const config = {
  apiKey: "AIzaSyBf9NTM90Pwz1kkpjG329BOnmXcwNRw1L8",
  authDomain: "freezer-status.firebaseapp.com",
  databaseURL: "https://freezer-status.firebaseio.com",
  projectId: "freezer-status",
  storageBucket: "freezer-status.appspot.com",
  messagingSenderId: "101036633888",
  appId: "1:101036633888:web:8c250f7492f8795133b145",
  measurementId: "G-2TZER30XVR"
};

export default config;
